.sign-account-background{
    background-color: #4A4D57;
    .sign-account-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        .header-title{
            text-align: center;
        }
        .header-bold{
            font-weight: bold;
        }

        .header-spacer{
            margin-top: 10px;
        }
        
        .logo-img{
            width: 150px;
            height: 150px;
        }
    }
}